@import "function";

.topbar {
    background-color: white !important;
}

a{
    color: black;
    text-decoration: none !important;

    &:not([href]){
        cursor: default;
        color: black !important;

        &:hover h2{
            color: black !important;
        }
    }

    &:hover h2{
        color: #98984B;
    }
}

.btn-floating i{
    width: auto;
}

.btn-floating.btn-large i{
    height: 0;
}

.white-text{

}

.ulTopbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 1em;
    padding-top: 1em;
    margin : auto;
}

.liTopbar {
    margin-top: auto;
    margin-bottom: auto;
}

.liTopbar_arrow {
    width: pxToVw(10px, 1920px);
    transition: all .2s ease-in-out;
    transform: rotate(-180deg);
}
.rotate{
    transform: rotate(-90deg);
}

.topbar{
    top: 0px;
    left: 0px;
    width: pxToPourcent(1920px, 1920px);
    height: pxToVw(120px, 1920px);
    font-size: pxToVw(20px, 1920px) !important;
}

.liTopbar div{
    font-size: 85% !important;
    cursor: pointer;
    color: #0a0a0a9e !important;
}

.liTopbar div > img{
    margin-left: pxToVw(10px, 1920px);
}

.liTopbar.img-topbar{
    width: pxToPourcent(58,1920);

    img{
        width: 100%;
    }
}

.material-icons.black-text.medium{
    display: block !important;
}

.submenu_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin : auto;
    width: pxToPourcent(1920px, 1920px);
    background-color: white;
    z-index: 550;
    position: absolute;
}
.submenu_title_container{
    width: pxToPourcent(1920px, 1920px);
    height: pxToVw(120px, 1920px);
    background-color: #E6E6DE;
    bottom: 0;
    display: flex;
}

.submenu_title_container h1{
    color: #98984B !important;
    font-size: pxToVw(24px, 1920px);
    font-weight: bold;
    text-align: center;
    margin: auto;
}

.submenu_content_container{
    display: flex;
    flex-wrap: wrap;
    margin-left: pxToVw(128px, 1920px);
}

.submenu_content{
    width: pxToVw(377px, 1920px);
    height: pxToVw(120px, 1920px);
    display: flex;
    margin: pxToVw(28px, 1920px) pxToVw(16px, 1920px);
}
.submenu_content_image{
    height: inherit;
    width: pxToVw(35px, 1920px);
}

.submenu_content_image a > img{
    margin-left: pxToVw(-15px, 1920px);
    margin-top: pxToVw(-7px, 1920px);
    width: pxToVw(35.8px, 1920px);
    height: pxToVw(36px, 1920px);
}

.submenu_content_text{
    height: inherit;
    width: pxToVw(335px, 1920px);
}

.submenu_content_title {
    width: inherit;
    color:black;
}

.submenu_content_title h2{
    font-size: pxToVw(24px, 1920px);
    margin: auto;
    font-weight: bold;
    margin-bottom: pxToVw(10px, 1920px);
    line-height: normal !important;

    // &:hover{
    // color: #98984B !important;

    // }
}

.submenu_content_description {
    height: pxToVw(60px, 1920px);
    width: inherit;
}

.submenu_content_description p{
    font-size: pxToVw(21px, 1920px);
    margin: auto;
    line-height: pxToVw(30px, 1920px);
    color: #777;
}
